import { basicCreator } from 'redux/ducks/helpers';
import * as locationActions from '../Locations/actions';
import * as postActions from '../actions';
import * as shiftActions from '../Shifts/actions';
import { uniq } from 'lodash';
import { initLocationsReducer } from '../Locations';
import { initShiftsReducer } from '../Shifts';
import { rateValid, shiftsByLocation } from 'helpers/post';
import { isEmpty } from 'lodash';

const constForNav = (name) => `go4ellis/Navigation/${name}`;
// Actions
export const SET_CURRENT_STEP = constForNav('SET_CURRENT_STEP');
export const SET_LOCATION_IDX = constForNav('SET_LOCATION_IDX');
export const VISIT_STEP = constForNav('VISIT_STEP');

// Action Creators
export const switchLocation = basicCreator(SET_LOCATION_IDX);
export const switchStep = basicCreator(SET_CURRENT_STEP);
export const visitStep = basicCreator(VISIT_STEP);

export const DEFAULT_NAVIGATION_STATE = {
  currentStep: 'type',
  locationIdx: 0,
  requestStatus: 'idle',
  visited: [],
};

// Reducer Functions
const initializePostReducer = (state, payload) => {
  const locations = initLocationsReducer(state.locations, payload) || [];
  const shifts = initShiftsReducer(state.shifts, payload);

  const visited = Array.from(
    locations.reduce((acc, location, idx) => {
      const locationShifts = shiftsByLocation(shifts, location);
      const pushToAcc = (...args) => args.forEach((arg) => acc.add(`L${idx}-${arg}`));

      if (locationShifts.every((s) => s.emergencyActionPlanId) && payload.version === 'v2') {
        pushToAcc('eap', 'details', 'credentials', 'supplies', 'rate', 'schedule', 'location');
      } else if (
        location.supplies.requiredCredentials !== null &&
        location.supplies.requiredCredentials !== undefined
      ) {
        pushToAcc('details', 'credentials', 'supplies', 'rate', 'schedule', 'location');
      } else if (!isEmpty(location.supplies.jobExpectations)) {
        pushToAcc('details', 'supplies', 'rate', 'schedule', 'location');
      } else if (!location.supplies.equipments.every(isEmpty)) {
        pushToAcc('supplies', 'rate', 'schedule', 'location');
      } else if (locationShifts.every(rateValid)) {
        pushToAcc('rate', 'schedule', 'location');
      } else if (locationShifts.every(({ id }) => id)) {
        pushToAcc('schedule', 'location');
      } else if (location.id || location.address?.id) pushToAcc('location');

      return acc;
    }, new Set())
  );

  if (6 * locations.length === visited.length) visited.push('post');

  return { ...state, currentStep: 'profile', visited };
};

const updatedPostReducer = (state) => {
  return {
    ...state,
    postWasUpdated: true,
  };
};

// Reducer
export default (state = DEFAULT_NAVIGATION_STATE, action = {}) => {
  switch (action.type) {
    case VISIT_STEP:
      return {
        ...state,
        visited: uniq([...state.visited, action.payload.step]),
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    case SET_LOCATION_IDX:
      return {
        ...state,
        locationIdx: action.payload.locationIdx,
      };

    case locationActions.DELETE_LOCATION:
      return { ...state, locationIdx: 0 };

    case postActions.INITIALIZE_POST:
      return initializePostReducer(DEFAULT_NAVIGATION_STATE, action.payload);
    case postActions.V2_RELOAD_FORM_SUCCESS:
      return initializePostReducer(state, action.payload);
    case postActions.V2_CLEAR_FORM:
      return DEFAULT_NAVIGATION_STATE;
    case postActions.SAVE_AS_DRAFT:
    case postActions.SAVE_PROFILE:
    case postActions.UPDATE_EVENT:
    case postActions.PUBLISH_DRAFT:
    case postActions.DELETE_DOCUMENT:
    case shiftActions.REMOVE_LOCATION:
      return { ...state, requestStatus: 'loading' };
    case postActions.SAVE_AS_DRAFT_SUCCESS:
    case postActions.SAVE_PROFILE_SUCCESS:
    case postActions.UPDATE_EVENT_SUCCESS:
    case postActions.PUBLISH_DRAFT_SUCCESS:
    case postActions.DELETE_DOCUMENT_SUCCESS:
    case shiftActions.REMOVE_LOCATION_SUCCESS:
      return { ...state, requestStatus: 'idle' };
    case postActions.SAVE_AS_DRAFT_ERROR:
    case postActions.SAVE_PROFILE_ERROR:
    case postActions.UPDATE_EVENT_ERROR:
    case postActions.PUBLISH_DRAFT_ERROR:
    case postActions.DELETE_DOCUMENT_ERROR:
    case shiftActions.REMOVE_LOCATION_ERROR:
      return { ...state, requestStatus: 'error' };
    case postActions.UPDATED_POST:
      return updatedPostReducer(state);
    default:
      return state;
  }
};
